import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block"
import {
    Content,
    Copy,
    Cover,
    CoverImg,
    Heading,
    Split,
    Title,
} from "./activities"
import FooterGallery from '../components/footer-gallery'

const Container = styled.div``

const SpecialsPage = ({ data }) => {
    const { title, footerGallery, subtitle, specials } = data.wpPage.specials
    return (
        <Layout>
            <SEO title="Specials" />
            <PageHeader title="Specials" />
            <TitleBlock title={title} copy={subtitle} />
            <Container>
                {specials.map((special, index) => (
                    <SpecialItem key={index} item={special} index={index} />
                ))}
            </Container>
            {footerGallery && footerGallery.length > 0 &&
                <FooterGallery gallery={footerGallery}/>
            }
        </Layout>
    )
}

export default SpecialsPage

export const SpecialsQuery = graphql`
    query specialsQuery {
        wpPage(title: { eq: "Specials" }) {
            specials {
                title
                subtitle
                footerGallery {
                    sourceUrl
                }
                specials {
                    description
                    subtitle
                    title
                    image {
                        sourceUrl
                    }
                }
            }
        }
    }
`

const SpecialItem = ({ item }) => {
    return (
        <Split>
            <Cover>
                <CoverImg
                    style={{ objectFit: "cover", marginBottom: 30 }}
                    src={item.image?.sourceUrl}
                />
            </Cover>
            <Content>
                <Title>{item.title}</Title>
                <Heading>{item.subtitle}</Heading>
                <Copy>{item.description}</Copy>
                {/* <MainButton onClick={() => showMeeting(true)}>
                    Request a Quote
                </MainButton> */}
            </Content>
        </Split>
    )
}
